<template>
  <banner-page v-if="!currentMember.hasOidc"
               :background-position="bannerBackgroundPosition"
               :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
               :background-color="bannerBackgroundColor">
    <div class="two-factor-migration">
      <div class="ri-body margin-bottom-xxs">כניסה</div>
      <div class="ri-large-headline ri-alte margin-bottom-xxs">
        {{ currentMember.firstNamePiiValue }}, כיף לראות אותך שוב,
        מהיום התזרים שלך מוגן אפילו יותר!
      </div>
      <span class="ri-large-body">
        בחודשים האחרונים שדרגנו את האבטחה, והגיע הזמן להוסיף לך
        <span class="bold">אמצעי הזדהות שני</span>
        שישמש אותך בכניסות הבאות לשירות.
      </span>
      <riseup-button class="margin-top-xl" @click="add2fa" title="הבנתי, אפשר להמשיך"/>
    </div>
  </banner-page>
  <loading-popup v-else title="עוד רגע נמשיך..." />
</template>

<script>

import DDLogs from '@/DDLogs';
import LoadingPopup from '@/base-components/onboarding/LoadingPopup';
import BaseUI from '@riseupil/base-ui';
import Segment from '../../../../Segment';
import CustomersApi from '../../../../api/CustomersApi';

export default {
  name: 'TwoFactorMigration',
  components: {
    BannerPage: BaseUI.Pages.BannerPage,
    RiseupButton: BaseUI.V2Components.RiseupButton,
    LoadingPopup,
  },
  data() {
    return {
      currentMember: {},
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
    };
  },
  async created() {
    this.currentMember = await CustomersApi.getPartiallyAuthenticatedMember();
    if (this.currentMember.hasOidc) {
      const { redirectTo } = this.$route.query;
      Segment.trackUserGot('GotTo2faMigrationPageWithOIDC', { redirectTo });
      DDLogs.log('The customer has Oidcs -> redirect to sr');
      window.location.href = `/${redirectTo || 'sr'}`;
      return;
    }
    Segment.trackUserGot('2faForcedMigrationEntered');
    DDLogs.log('The customer hasn\'t an Oidc -> redirect to Forced Migration');
  },
  methods: {
    async add2fa() {
      Segment.trackUserInteraction('2faAdd2faClicked');
      DDLogs.log('Customer clicked Add2faClicked');
      window.location.href = '/api/partial-auth/login-with-auth0/migration';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.two-factor-migration {
  display: flex;
  flex-direction: column;
  margin: 30px;
  .bold {
    font-weight: bold;
  }
}
</style>
