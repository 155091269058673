<template>
  <upsell-page title="חיסכון לטווח קצר"
               cta-text="אני רוצה לשמוע עוד"
               headline="למי זה יכול להתאים?"
               img-src="/images/saving-zone/balance-money/balance-money-header-image.svg"
               :pageBackgroundColor="backgroundColor"
               :text-color="textColor"
               @click="buttonAction">
    אם יש לך כסף פנוי ששוכב בעו״ש, והתלבטת מה כדאי לעשות איתו, אפשר להכיר כמה אפשרויות ולהבין איך  לצאת לדרך.
  </upsell-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import BalanceMoneyFlow from '../cashflow-app/personalized-savings/balance-money/BalanceMoneyFlow';

export default {
  name: 'BalanceMoneyPage',
  components: {
    UpsellPage: BaseUI.Pages.UpsellPage,
  },
  created() {
    if (this.allCashflows.length === 0) {
      this.initBudgets({ budgetDate: 'current' });
    }
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
      textColor: BaseUI.Colors.riseupDarkGreen,
      logoColor: BaseUI.Colors.riseupDarkGreen,
      sticky: false,
    });
    Segment.trackUserInteraction('BalanceMoneyPage_Entered');
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupLightBlue,
      textColor: BaseUI.Colors.riseupBlack,
    };
  },
  methods: {
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    buttonAction() {
      Segment.trackUserInteraction('BalanceMoneyPage_ClickedOpenModal');
      this.openModal({ component: BalanceMoneyFlow, props: { origin: 'MoneyFundPage' } });
    },
  },
  computed: {
    ...mapState('cashflowView', ['allCashflows']),
  },
};
</script>

<style scoped lang="scss">
@import "../cashflow-app/personalized-savings/balance-money/balance-money-styles";

</style>
